<template>
    <div class="heading">
        <h2 class="title">Company wide attach Vector Insights</h2>
    </div>
    <div class="overview-row alter">
        <div class="screen">
            <img src="~@/assets/images/overview-screen-03.png" alt="">
        </div>
        <div class="info">
            <ul>
                <li>
                    <h4 class="sub-title">Real-time Attack Vector Insights</h4>
                    <p>Dynamic Risk Management: Instantly assess employee vulnerabilities with our dashboard, offering insights into potential threats from data brokers and search sites for effective, proactive defence.</p>
                </li>
                <li>
                    <h4 class="sub-title">External Data Privacy Audits</h4>
                    <p>Streamlined Security Audits: Get a clear, real-time overview of your company's data exposure with our comprehensive audits, enabling immediate tightening of privacy controls.</p>
                </li>
                <li>
                    <h4 class="sub-title">Live Dashboard to Track Your Progress</h4>
                    <p>Privacy Progress Tracking: Visualize your data protection journey in real time with our dashboard, highlighting the effectiveness of your data removals.</p>
                </li>
            </ul>
        </div>
    </div>
    <div class="platform-stripe">
        <h2 class="title">Explore Privacy Bee’s Data Privacy Platform</h2>
        <p>Clean up your team’s PII and lessen your attack vector.</p>
        <a href="#contact" class="btn-link">Learn More</a>
        <img src="~@/assets/images/orwell-platform.svg" alt="Mr. Orwell" class="orwell">
    </div>
    <div class="heading">
        <h2 class="title">Real time risk dashboard</h2>
    </div>
    <div class="overview-row">
        <div class="screen">
            <img src="~@/assets/images/overview-screen-01.png" alt="">
        </div>
        <div class="info">
            <ul>
                <li>
                    <h4 class="sub-title">Immediate Deletion Alerts</h4>
                    <p>Stay informed with each data deletion action we take, directly through your dashboard, enhancing transparency and commitment to privacy.</p>
                </li>
                <li>
                    <h4 class="sub-title">Personalized Risk Score</h4>
                    <p>Customized Employee Risk Insights: Equip each employee with a personal risk score, providing a dynamic and motivating tool for enhancing individual data privacy awareness.</p>
                </li>
                <li>
                    <h4 class="sub-title">Live Activity Feed</h4>
                    <p>Real-Time Privacy Monitoring: Our live feed keeps you updated with every action we take on your behalf, from data deletions to identifying new exposures, showcasing our active role in protecting your privacy.</p>
                </li>
            </ul>
        </div>
    </div>
    <div class="heading">
        <h2 class="title">Vendor Risk Management</h2>
    </div>
    <div class="overview-row alter">
        <div class="screen">
            <img src="~@/assets/images/overview-screen-02.png" alt="">
        </div>
        <div class="info">
            <ul>
                <li>
                    <h4 class="sub-title">Revolutionized Vendor Risk Evaluation</h4>
                    <p>Advance your vendor risk assessments by focusing on Vendor PII management, effectively guarding against common threats like spear phishing and social engineering.</p>
                </li>
                <li>
                    <h4 class="sub-title">Intuitive VRM Dashboard</h4>
                    <p>Quickly manage vendor risks with our clear, graphical VRM Dashboard, making complex data simple to navigate and act upon for aligned vendor-company security.</p>
                </li>
                <li>
                    <h4 class="sub-title">Proactive Vendor Onboarding</h4>
                    <p>Screen vendors efficiently using our VRM Dashboard's graphical analysis, ensuring partnerships align with your privacy standards to mitigate 3rd party breach risks and strengthen defences.</p>
                </li>
            </ul>
        </div>
    </div>
    <div class="overview-action">
        <a href="#contact" class="btn btn-primary">Get a Free Demo</a>
    </div>
    <img src="~@/assets/images/orwell-overview.svg" alt="Mr. Orwell" class="orwell">
</template>

<script>
export default {
  name: 'AppOverview'
}
</script>

<style scoped> </style>