import { createApp } from 'vue'
import App from './App.vue'

createApp(App).mount('#app')


var form = document.querySelector('#contact');
form.onsubmit = function () {
    showHideLoader(true);
    var xhr = new XMLHttpRequest();
    var formData = new FormData(form);
    //open the request
    xhr.open('POST', 'https://hooks.zapier.com/hooks/catch/10419791/3wrqayj/');


    /* If we need the json format  */
    //xhr.setRequestHeader("Content-Type", "application/json");
    //var json = JSON.stringify(Object.fromEntries(formData));
    //xhr.send(json);

    const queryParams = new URLSearchParams(window.location.search);
    for(var value of queryParams.keys()) {
        formData.append(value, queryParams.get(value));
    }

    /** If we need the form data format */
    xhr.send(formData);

    xhr.onreadystatechange = function () {
        if (xhr.readyState == XMLHttpRequest.DONE) {

            showHideLoader(false);
            const data = JSON.parse(this.responseText);
            if (data.status == 'success') {
                showHideSuccessMessage(true);
                showHideErrorMessage(false);
                showHideForm(false);
                setTimeout(() => {
                    redirectToCalendly();
                    showHideForm(true);
                    showHideSuccessMessage(false);
                    form.reset();
                    document.getElementById('select-area-data').innerText = '';
                }, 5000);
            } else {
                showHideSuccessMessage(false);
                showHideErrorMessage(true);
                showHideForm(true);
            }
        }
    }

    //Dont submit the form.
    return false;
}
document.getElementById('select-area-data').onclick = function () {
    var element = document.getElementById("checkbox-data");
    if (element.style.display === "none") {
        element.style.display = "flex";
    } else {
        element.style.display = "none";
    }
}
document.getElementById('checkbox-form').onclick = function (e) {
    e.stopPropagation();
}
document.onclick = function () {
    var element = document.getElementById("checkbox-data");
    element.style.display = "none";
}
var checkbox = document.getElementsByClassName('checkbox');
for (var i = 0; i < checkbox.length; i++) {
    var checkboxElement = checkbox[i];
    checkboxElement.onclick = function () {
        const checkboxCount = document.querySelectorAll('#checkbox-data input[type="checkbox"]:checked').length;
        if (checkboxCount > 0) {
            document.getElementById('select-area-data').innerText = checkboxCount + ' items selected';
        } else {
            document.getElementById('select-area-data').innerText = 'Select Data';
        }
    }
}
function showHideLoader(isShow) {
    document.getElementById('loader').style.display = isShow ? "flex" : "none";
}

function showHideForm(isShow) {
    document.getElementById('contact').style.display = isShow ? "block" : "none";
}
function showHideSuccessMessage(isShow) {
    document.getElementById('sucess-block').style.display = isShow ? "flex" : "none";
}
function showHideErrorMessage(isShow) {
    document.getElementById('error-block').style.display = isShow ? "flex" : "none";
}
function redirectToCalendly() {
    Object.assign(document.createElement('a'), {
        rel: 'noopener noreferrer',
        //target: '_blank',
        href: 'https://calendly.com/privacybee/exploring-external-data-privacy',
    }).click();
}
