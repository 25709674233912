<template>
    <div class="heading">
        <h2 class="title">See the Results Our Customers Have Achieved</h2>
    </div>
    <div class="review-blocks">
        <div class="review-block">
            <h3 class="sub-title">Government Agency Strengthens Security with Privacy Bee</h3>
            <p>A government agency used Privacy Bee for Business to boost external data privacy, reducing threats and recruitment challenges within a year.</p>
            <a href="https://business.privacybee.com/resource-center/reduction-in-physical-threats-against-public-health-agency-workforce/" class="btn-link" target="_blank">Read case study <i></i></a>
        </div>
        <div class="review-block">
            <h3 class="sub-title">Privacy Bee Rescues Financial Firm</h3>
            <p>A financial firm used Privacy Bee for Business to address a 4% client decline and potential $10 million loss, successfully reversing the trend and extending the solution to a sister firm.</p>
            <a href="https://business.privacybee.com/resource-center/finance-company-reverses-sales-slump-by-promoting-increased-external-data-privacy-management-practices/" class="btn-link" target="_blank">Read case study <i></i></a>
        </div>
        <div class="review-block">
            <h3 class="sub-title">Privacy Bee Powers REIT Triumph in Post-COVID Era</h3>
            <p>A $1.175 billion REIT managing affordable housing overcame post-COVID challenges by transitioning to Privacy Bee's privacy services, boosting new leases, tenant retention, and brand reputation while reducing turnover costs.</p>
            <a href="https://business.privacybee.com/resource-center/reit-improves-occupancy-with-privacy-management/" class="btn-link" target="_blank">Read case study <i></i></a>
        </div>
    </div>
    <div class="review-pinned">
        <div class="brand-info">
            <div class="logo">
                <img src="~@/assets/images/capterra-logo.svg" alt="Capterra">
            </div>
            <p class="lead">Compliance Manager Computer Software, 501-1000 employees</p>
        </div>
        <div class="review-text">
            <div class="divider"></div>
            <p>Privacy Bee transformed our data security, improving recruitment and retention within a year. Their proactive approach also reduced spear phishing risks. Highly recommended for comprehensive privacy solutions!</p>
        </div>
    </div>
    <div class="free-demo-wrapper">
        <h2 class="title">Privacy Bee: Streamlining Data Removal for Enhanced Information Security</h2>
        <p>See first hand how Privacy Bee facilitates seamless data removal, helping companies safeguard sensitive information by efficiently eliminating personal data from various sources.</p>
        <a href="#contact" class="btn btn-primary">Get a Free Demo</a>
    </div>
</template>

<script>
export default {
  name: 'CustomerFeedback'
}
</script>

<style scoped> </style>