<template>
    <section class="hero-banner-wrapper">
        <header class="site-header">
            <div class="brand-logo">
                <img src="~@/assets/images/privacy-bee-logo.svg" alt="PrivacyBee Business">
            </div>
        </header>
        <div class="container">
            <HeroBannerForm />
        </div>
    </section>
    <section class="enterprice-wrapper">
        <div class="container">
            <EnterpriceBusiness />
        </div>
    </section>
    <section class="app-overview-wrapper">
        <div class="container">
            <AppOverview />
        </div>
    </section>
    <section class="bottom-gradient">
        <div class="customer-feedback-wrapper">
            <div class="container">
                <CustomerFeedback />
            </div>
        </div>
        <footer class="site-footer">
            <SiteFooter />
        </footer>
    </section>
</template>

<script>
import HeroBannerForm from './components/HeroBannerForm.vue'
import EnterpriceBusiness from './components/EnterpriceBusiness.vue'
import AppOverview from './components/AppOverview.vue'
import CustomerFeedback from './components/CustomerFeedback.vue'
import SiteFooter from './components/SiteFooter.vue'

export default {
    name: 'App',
    components: {
        HeroBannerForm,
        EnterpriceBusiness,
        AppOverview,
        CustomerFeedback,
        SiteFooter
    }
}
</script>

<style lang="scss">
@import "~@/assets/scss/pb-theme.scss";
</style>