<template>
    <div class="business-wrapper">
        <h2 class="title">Experience Enterprise-Grade Business Privacy</h2>
        <div class="key-features">
            <div class="screen">
                <img src="~@/assets/images/enterprice-screen.png" alt="Enterprice Business">
            </div>
            <div class="feature-bullets">
                <ul>
                    <li>
                        <h3 class="sub-title">Decrease your risk of data breaches</h3>
                        <p>Exposed employee PII is a leading indicator of future data breaches. Poorly managed EDP is like a juicy steak for hackers looking to personalize their attacks.</p>
                    </li>
                    <li>
                        <h3 class="sub-title">Protect your employees, protect your company</h3>
                        <p>Most mandatory security training isn't taken seriously, and it just takes one employee getting tricked one time to constitute an expensive data breach.</p>
                    </li>
                    <li>
                        <h3 class="sub-title">One unified platform for External Data Privacy</h3>
                        <p>Consolidate half a dozen vendors with Privacy Bee's business suite, from GRC to data broker removal to vendor breach alerts to supply chain hardening.</p>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="cybersecurity">
        <h2 class="title fancy">Cybersecurity is not enough!</h2>
        <p>100% of the companies that got breached last year had good cybersecurity, and likely all the same things you have.</p>
        <a href="#contact" class="btn btn-primary">Learn More about EDP</a>
        <img src="~@/assets/images/orwell-corporate.svg" alt="Mr. Orwell" class="orwell">
    </div>
</template>

<script>
export default {
  name: 'EnterpriceBusiness'
}
</script>

<style scoped> </style>