<template>
    <div class="left-panel">
        <h1 class="title">Request a <span class="fancy">personalized demo</span> of Privacy Bee to better defend against threats</h1>
        <p>The most effective technique hackers use to breach a company is PII-infused social engineering and spear phishing, which tricks employees and bypasses cybersecurity. A strong External Data Privacy layer will harden your defenses against these emerging threats.</p>
        <p class="lead">Over 1,500+ companies trust Privacy Bee to protect their employees</p>
        <div class="client-logos">
            <div class="client-logo">
                <img src="~@/assets/images/aetna.png" alt="Aetna">
            </div>
            <div class="client-logo">
                <img src="~@/assets/images/staples.png" alt="Staples">
            </div>
            <div class="client-logo">
                <img src="~@/assets/images/goodrx.png" alt="GoodRx">
            </div>
            <div class="client-logo">
                <img src="~@/assets/images/tom-ford.png" alt="Tom Ford">
            </div>
            <div class="client-logo">
                <img src="~@/assets/images/cloudera.png" alt="Cloudera">
            </div>
            <div class="client-logo">
                <img src="~@/assets/images/verizon.png" alt="Verizon">
            </div>
            <div class="client-logo">
                <img src="~@/assets/images/gannett.png" alt="Gannett">
            </div>
            <div class="client-logo">
                <img src="~@/assets/images/basf.png" alt="BASF">
            </div>
        </div>
    </div>
    <div class="form-wrapper">
        <img src="~@/assets/images/orwell-demoform.svg" alt="Mr. Orwell" class="orwell">
        <div id="loader" class="form-loader">
            <img src="~@/assets/images/hive-loader.svg" alt="Hive Loading">
        </div>
        <div id="sucess-block" class="form-message">
            <img src="~@/assets/images/form-success-icon.svg" alt="" class="icon">
            <h3 class="title">Got it!</h3>
            <p>Thanks for reaching out. <br>Now let's schedule an introductory call...</p>
            <a href="https://calendly.com/privacybee/exploring-external-data-privacy" class="btn btn-primary" target="_blank">Continue</a>
        </div>
        <div id="error-block" class="form-message">
            <img src="~@/assets/images/form-error-icon.svg" alt="" class="icon">
            <h3 class="title">Ooops,</h3>
            <p>Something went wrong. Let’s try again.</p>
        </div>
        <form id="contact">
            <h2 class="title">100% <span>Free</span>, No Risk Pilot</h2>
            <div class="form-row col-half">
                <div class="input-block">
                    <label class="control-label">First Name <span class="req">*</span></label>
                    <input type="text" class="form-control" name="first_name" required>
                </div>
                <div class="input-block">
                    <label class="control-label">Last Name <span class="req">*</span></label>
                    <input type="text" class="form-control" name="last_name" required>
                </div>
            </div>
            <div class="form-row col-half">
                <div class="input-block">
                    <label class="control-label">Work Email <span class="req">*</span></label>
                    <input type="email" class="form-control" name="work_email" required>
                </div>
                <div class="input-block">
                    <label class="control-label">Direct Phone <span class="req">*</span></label>
                    <input type="phone" class="form-control" name="phone" required>
                </div>
            </div>
            <div class="form-row col-half">
                <div class="input-block">
                    <label class="control-label">Company Name <span class="req">*</span></label>
                    <input type="text" class="form-control" name="company_name" required>
                </div>
                <div class="input-block">
                    <label class="control-label">Title <span class="req">*</span></label>
                    <input type="text" class="form-control" name="title" required>
                </div>
            </div>
            <div class="form-row" id="checkbox-form">
                <div class="input-block">
                    <label class="control-label">Areas of Interest</label>
                    <div id="select-area-data" class="form-control dropdown-toggle"></div>
                    <div class="form-checks" id="checkbox-data" style="display: none;">
                        <div class="check-col">
                            <label class="checkbox">
                                <input type="checkbox" name="interest[]" value="Breach Risk Reduction">
                                <span>Breach Risk Reduction</span>
                            </label>
                        </div>
                        <div class="check-col">
                            <label class="checkbox">
                                <input type="checkbox" name="interest[]" value="Employee Poach Defense">
                                <span>Employee Poach Defense</span>
                            </label>
                        </div>
                        <div class="check-col">
                            <label class="checkbox">
                                <input type="checkbox" name="interest[]" value="Incident Response">
                                <span>Incident Response</span>
                            </label>
                        </div>
                        <div class="check-col">
                            <label class="checkbox">
                                <input type="checkbox" name="interest[]" value="Telemarketing Defense">
                                <span>Telemarketing Defense</span>
                            </label>
                        </div>
                        <div class="check-col">
                            <label class="checkbox">
                                <input type="checkbox" name="interest[]" value="Physical Safety">
                                <span>Physical Safety</span>
                            </label>
                        </div>
                        <div class="check-col">
                            <label class="checkbox">
                                <input type="checkbox" name="interest[]" value="Identity Theft Defense">
                                <span>Identity Theft Defense</span>
                            </label>
                        </div>
                        <div class="check-col">
                            <label class="checkbox">
                                <input type="checkbox" name="interest[]" value="Employee Privacy Training">
                                <span>Employee Privacy Training</span>
                            </label>
                        </div>
                        <div class="check-col">
                            <label class="checkbox">
                                <input type="checkbox" name="interest[]" value="M&amp;A InfoSec Auditing">
                                <span>M&amp;A InfoSec Auditing</span>
                            </label>
                        </div>
                        <div class="check-col">
                            <label class="checkbox">
                                <input type="checkbox" name="interest[]" value="Executive Protection">
                                <span>Executive Protection</span>
                            </label>
                        </div>
                        <div class="check-col">
                            <label class="checkbox">
                                <input type="checkbox" name="interest[]" value="Other">
                                <span>Other</span>
                            </label>
                        </div>
                        <div class="check-col">
                            <label class="checkbox">
                                <input type="checkbox" name="interest[]" value="Vendor Privacy Risk">
                                <span>Vendor Privacy Risk</span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-row">
                <button type="submit" class="btn btn-primary">Request Demo</button>
            </div>
            <div class="form-msg">
                <p>When you submit this form you consent to us emailing you occasionally about our products and services. You can unsubscribe from emails at any time, and we will never pass your email onto third parties. <a href="https://privacybee.com/privacy-policy/" class="btn-link" target="_blank">Privacy Policy</a>.</p>
            </div>
        </form>
    </div>
</template>

<script>

export default {
  name: 'HeroBannerForm'
}
</script>

<style scoped></style>