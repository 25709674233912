<template>
    <div class="container">
        <div class="copyright">Copyright &copy; 2023 <strong>Privacy Bee</strong></div>
        <img src="~@/assets/images/orwell-footer.svg" alt="Mr. Orwell" class="orwell">
        <div class="footer-links">
            <a href="https://privacybee.com/privacy-policy/" target="_blank">Privacy Policy</a>
            <a href="https://business.privacybee.com/terms-and-conditions/" target="_blank">Terms of Service</a>
        </div>
    </div>
</template>

<script>
export default {
  name: 'SiteFooter'
}
</script>

<style scoped> </style>